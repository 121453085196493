import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Card from "../../components/cards";
import TitleHeader from "../../components/titleHeader";
import Button from "../../components/button";
import Header from "../header";
import Footer from "../footer";
import api from "../../api";

import {
  Container,
  ContainerRealEstate,
  Content,
  ResultsMessage,
} from "./styles";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RealestateSearchResults = () => {
  const navigate = useNavigate();
  const query = useQuery();

  const area = query.get("area");
  const city = query.get("city");
  const state = query.get("state");
  const status = query.get("status");
  const type = query.get("type");

  const [realestate, setRealestate] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const statusArray = status ? status.split(",") : [];

  const processedType =
    type === "rooftop_apartment"
      ? ["penthouse_duplex", "penthouse", "duplex", "triplex"]
      : type === "house"
      ? ["condominium_house", "house"]
      : [type];

  const data = {
    area: [area],
    city: city,
    finality: ["residential"],
    min_price_per_private_square_meter: 20000,
    state: state,
    status: statusArray,
    type: processedType,
  };

  const renderRealEstate = async () => {
    setLoading(true);
    try {
      await api
        .get("/buildings", {
          params: data,
        })
        .then((response) => {
          setRealestate(response.data.buildings);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    } catch (error) {
      console.log("erro: ", error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreRealestate = async () => {
    setDisabled(true);

    try {
      const response = await api.get("/buildings", {
        params: { ...data, page: page + 1 },
      });
      setRealestate((prevRealEstate) => [
        ...prevRealEstate,
        ...response.data.buildings,
      ]);
      setPage(page + 1);
      setDisabled(false);
    } catch (error) {
      console.log("Erro ao renderizar mais imóveis: ", error);
      setDisabled(false);
    }
  };

  useEffect(() => {
    renderRealEstate();
  }, [area, city, state, status, type]);

  return (
    <Container>
      <Header />
      <Content>
        <TitleHeader title="Resultado da sua pesquisa" className="title" />

        {loading ? (
          <ResultsMessage>CARREGANDO...</ResultsMessage>
        ) : realestate.length === 0 ? (
          <ResultsMessage>Nenhum resultado para sua pesquisa</ResultsMessage>
        ) : (
          <ContainerRealEstate>
            {realestate.map((item, index) => {
              return (
                <Card
                  key={index}
                  image={item.default_image["520x280"]}
                  location={item.address.area}
                  squareMeters={item.min_area}
                  parkingSpaces={item.min_parking}
                  dormitory={item.min_bedrooms}
                  value={item.min_price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })}
                  name={item.name}
                  suite={item.min_suites}
                  onClick={() => navigate(`/imoveis/${item.id}`)}
                />
              );
            })}
          </ContainerRealEstate>
        )}

        {page < totalPage && (
          <Button
            onClick={loadMoreRealestate}
            backgroundColor={"#14202E"}
            disabled={disabled}
            icon={false}
          >
            {disabled ? "CARREGANDO..." : "CARREGAR MAIS RESULTADOS"}
          </Button>
        )}
      </Content>
      <Footer />
    </Container>
  );
};

export default RealestateSearchResults;
