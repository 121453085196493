import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Footer from "../footer";
import Header from "../header";
import InfosRealestate from "./infosRealestate";
import api from "../../api";

import { Container, Content, LoadingMessage } from "./styles";

const realestate = {
  id: 1,
  name: "Joya Jardins",
  neighborhood: "Jardins",
  squareMeters: "81.19",
  price: 2395104.93,
  suites: 2,
  bathrooms: 3,
  parkingSpots: 1,
  address: {
    area: "Jardins",
    latitude: -23.558078589930524,
    longitude: -46.665239545591405,
  },
  description:
    "JOYA Jardins não é apenas um empreendimento, é uma obra-prima que redefine o conceito de exclusividade e elegância.",
  features: [
    "Lobby com clausura de acesso",
    "Festa gourmet",
    "Academia technogym",
    "Delivery",
    "Lounge externo para acesso da piscina",
    "Piscina com solarium e raia semi olímpica (125 metros)",
    "Piazzetta",
    "Terraço gourmet no rooftop",
    "Área wellness no rooftop com sauna seca, massagem e spa",
  ],
  stage: "Lançamento",
  launch_date: "10/2024",
  opening_date: "09/2027",
  total_units: 62,
};

const RealestateInformation = () => {
  const { id } = useParams();
  const [dataRealestate, setDataRealestate] = useState();
  const [loading, setLoading] = useState(false);

  const RenderRealestate = async () => {
    setLoading(true);
    try {
      const response = await api.get(`buildings/${id}`);
      setDataRealestate(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id !== "1") {
      RenderRealestate();
    }
  }, [id]);

  return (
    <Container>
      <Header />
      <Content>
        {loading && <LoadingMessage>Carregando...</LoadingMessage>}

        {id === "1" ? (
          <InfosRealestate
            name={realestate.name}
            location={realestate.address.area}
            value={realestate.price.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
            squareMeters={realestate.squareMeters}
            suite={realestate.suites}
            parkingSpaces={realestate.parkingSpots}
            bedroom={realestate.suites}
            description={realestate.description}
            latitude={realestate.address.latitude}
            longitude={realestate.address.longitude}
            features={realestate.features}
            stage={realestate.stage}
            launch_date={realestate.launch_date}
            total_units={realestate.total_units}
            opening_date={realestate.opening_date}
            bathrooms={realestate.bathrooms}
          />
        ) : (
          dataRealestate && (
            <InfosRealestate
              name={dataRealestate.name}
              location={dataRealestate.address.area}
              value={dataRealestate.min_price.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
              squareMeters={dataRealestate.min_area}
              suite={dataRealestate.min_suites}
              parkingSpaces={dataRealestate.min_parking}
              bedroom={dataRealestate.min_bedrooms}
              description={dataRealestate.description}
              latitude={dataRealestate.address.latitude}
              longitude={dataRealestate.address.longitude}
              bathrooms={dataRealestate.min_bathrooms}
              stage={dataRealestate.stage}
              launch_date={dataRealestate.launch_date}
              opening_date={dataRealestate.opening_date}
              total_units={dataRealestate.total_units}
              features={dataRealestate.features}
            />
          )
        )}
      </Content>
      <Footer />
    </Container>
  );
};

export default RealestateInformation;
