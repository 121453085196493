import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Card from "../cards/banner";

import {
  CardOverlay,
  CarouselSlide,
  CarouselSlides,
  CarouselWrapper,
  Indicator,
  IndicatorContainer,
  SlideContent,
  SlideImage,
} from "./styles";

const Carousel = ({ realestates }) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const length = realestates.length;
  const transitionRef = useRef(null);

  const cloneSlides = [realestates[length - 1], ...realestates, realestates[0]];

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handlePrev = () => {
    setIsTransitioning(true);
    setIndex((prevIndex) => prevIndex - 1);
  };

  const handleNext = () => {
    setIsTransitioning(true);
    setIndex((prevIndex) => prevIndex + 1);
  };

  const handleIndicatorClick = (i) => {
    setIsTransitioning(true);
    setIndex(i + 1);
  };

  useEffect(() => {
    if (index === 0) {
      transitionRef.current = setTimeout(() => {
        setIsTransitioning(false);
        setIndex(length);
      }, 500);
    } else if (index === length + 1) {
      transitionRef.current = setTimeout(() => {
        setIsTransitioning(false);
        setIndex(1);
      }, 500);
    }
    return () => clearTimeout(transitionRef.current);
  }, [index, length]);

  return (
    <CarouselWrapper>
      <CarouselSlides
        index={index}
        style={{
          transition: isTransitioning ? "transform 0.5s ease-in-out" : "none",
        }}
      >
        {cloneSlides.map((realestate, i) => (
          <CarouselSlide key={i}>
            <SlideContent>
              <SlideImage src={realestate.image} alt={realestate.title} />
              <CardOverlay>
                <Card
                  title={realestate.title}
                  location={realestate.location}
                  size={realestate.size}
                  garage={realestate.garage}
                  suite={realestate.suite}
                  bedroom={realestate.bedroom}
                  onButtonClick={() => navigate(`/imoveis/${realestate.id}`)}
                />
              </CardOverlay>
            </SlideContent>
          </CarouselSlide>
        ))}
      </CarouselSlides>

      <IndicatorContainer>
        {realestates.map((_, i) => (
          <Indicator
            aria-label="Próximo slide"
            key={i}
            style={{
              backgroundColor: i === (index - 1) % length ? "white" : "#A4A4A4",
            }}
            onClick={() => handleIndicatorClick(i)}
          />
        ))}
      </IndicatorContainer>
    </CarouselWrapper>
  );
};

export default Carousel;
