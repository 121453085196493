import styled from "styled-components";

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 90vh;
  margin: 0 auto;
  overflow: hidden;
  border-bottom: 5px solid #14202e;

  @media (max-width: 500px) {
    height: 600px;
  }
`;

export const CarouselSlides = styled.div`
  display: flex;
  transform: ${({ index }) => `translateX(-${index * 100}%)`};
  height: 100%;
`;

export const CarouselSlide = styled.div`
  min-width: 100%;
  position: relative;
  height: 100%;
`;

export const SlideContent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(20, 32, 46, 0.9),
      rgba(20, 32, 46, 0)
    );
  }
`;

export const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CardOverlay = styled.div`
  position: absolute;
  bottom: 55px;
  left: 50%;
  margin-left: 200px;
  transform: translateX(-50%);
  width: 80%;
  z-index: 2;

  @media (max-width: 800px) {
    bottom: 79px;
    margin-left: 29px;
    transform: translateX(0);
    left: 0;
  }
`;

export const IndicatorContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
`;

export const Indicator = styled.button`
  width: 56px;
  height: 3px;
  border: none;
  cursor: pointer;
`;
