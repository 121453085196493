import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "../button";

import {
  CheckboxGroup,
  CheckboxOptionLabel,
  Wrapper,
  ButtonWrapper,
  InputGroup,
  FormContent,
  Form,
  TextInput,
  InputLabel,
  DropdownSelect,
  Heading,
  FieldsWrapper,
} from "./styles";
import Swal from "sweetalert2";

const SearchTwo = ({ onSearch }) => {
  const { register, handleSubmit, reset } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (!data.status[0] && !data.status[1]) {
      Swal.fire({
        title: "Você deve selecionar pelo menos um status.",
        icon: "info",
        iconColor: "#c29b40",
        confirmButtonColor: "#14202e",
      });
      return;
    }

    navigate(
      `/buscar-imoveis?area=${data.neighborhood}&city=${data.city}&state=${data.state}&status=${data.status}&type=${data.typeRealestate}`
    );
    reset();
    onSearch();
  };

  const neighborhood = [
    "Cerqueira César",
    "Consolação",
    "Higienópolis",
    "Ibirapuera",
    "Itaim Bibi",
    "Jardim Paulista",
    "Moema",
    "Paraíso",
    "Vila Nova Conceição",
  ];

  return (
    <Wrapper>
      <FormContent>
        <Heading>Que imóvel você procura?</Heading>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <FieldsWrapper>
            <InputGroup>
              <InputLabel>Tipo de imóvel</InputLabel>
              <DropdownSelect {...register("typeRealestate")}>
                <option value="apartments">Apartamento</option>
                <option value="house">Casa</option>
                <option value="rooftop_apartment">Cobertura</option>
              </DropdownSelect>
            </InputGroup>

            <InputGroup>
              <InputLabel>Estado</InputLabel>
              <TextInput
                {...register("state", { required: true })}
                value="SP"
                readOnly
              />
            </InputGroup>

            <InputGroup>
              <InputLabel>Cidade</InputLabel>
              <TextInput
                {...register("city", { required: true })}
                value="São Paulo"
                readOnly
              />
            </InputGroup>

            <InputGroup>
              <InputLabel>Bairro</InputLabel>

              <DropdownSelect {...register("neighborhood")}>
                {neighborhood.map((neighborhood, index) => {
                  return (
                    <option value={neighborhood} key={index}>
                      {neighborhood}
                    </option>
                  );
                })}
              </DropdownSelect>
            </InputGroup>

            <CheckboxGroup>
              <InputLabel>Status</InputLabel>
              <CheckboxOptionLabel>
                <input type="checkbox" {...register("status")} value="ready" />
                Pronto para morar
              </CheckboxOptionLabel>
              <CheckboxOptionLabel>
                <input
                  type="checkbox"
                  {...register("status")}
                  value="under_construction"
                />
                Em construção
              </CheckboxOptionLabel>
            </CheckboxGroup>
          </FieldsWrapper>
          <ButtonWrapper>
            <Button icon={false}>BUSCAR</Button>
          </ButtonWrapper>
        </Form>
      </FormContent>
    </Wrapper>
  );
};

export default SearchTwo;
