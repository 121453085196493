import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Title = styled.h1`
  font-size: 36px;
  font-family: "Playfair Display", sans-serif;
  font-weight: bold;
  color: #14202e;
  margin-bottom: 6px;
`;

export const Subtitle = styled.span`
  font-size: 16px;
  color: #14202e;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 13px;
`;

export const Line = styled.div`
  height: 2px;
  background-color: #c29b40;
  width: 90px;
`;
