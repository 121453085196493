import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "../button";

import {
  CheckboxContainer,
  CheckboxLabel,
  FormContainer,
  Input,
  Label,
  Select,
  Title,
} from "./styles";
import Swal from "sweetalert2";

const SearchForm = () => {
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    if (!data.status[0] && !data.status[1]) {
      Swal.fire({
        title: "Você deve selecionar pelo menos um status.",
        icon: "info",
        iconColor: "#c29b40",
        confirmButtonColor: "#14202e",
      });
      return;
    }

    navigate(
      `/buscar-imoveis?area=${data.neighborhood}&city=${data.city}&state=${data.state}&status=${data.status}&type=${data.typeRealestate}`
    );
  };

  const neighborhood = [
    "Cerqueira César",
    "Consolação",
    "Higienópolis",
    "Ibirapuera",
    "Itaim Bibi",
    "Jardim Paulista",
    "Moema",
    "Paraíso",
    "Vila Nova Conceição",
  ];

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Title>Que imóvel você procura?</Title>

      <Label>Tipo de imóvel</Label>

      <Select {...register("typeRealestate", { required: true })}>
        <option value="apartments">Apartamento</option>
        <option value="house">Casa</option>
        <option value="rooftop_apartment">Cobertura</option>
      </Select>
      <Label>Estado</Label>
      <Input {...register("state", { required: true })} value="SP" readOnly />
      <Label>Cidade</Label>
      <Input
        {...register("city", { required: true })}
        value="São Paulo"
        readOnly
      />

      <Label>Bairro</Label>

      <Select {...register("neighborhood", { required: true })}>
        {neighborhood.map((neighborhood, index) => {
          return (
            <option value={neighborhood} key={index}>
              {neighborhood}
            </option>
          );
        })}
      </Select>

      <Label>Status</Label>
      <CheckboxContainer>
        <CheckboxLabel>
          <input type="checkbox" {...register("status")} value="ready" />
          Pronto para morar
        </CheckboxLabel>
        <CheckboxLabel>
          <input
            type="checkbox"
            {...register("status")}
            value="under_construction"
          />
          Em construção
        </CheckboxLabel>
      </CheckboxContainer>

      <Button icon={false}>BUSCAR</Button>
    </FormContainer>
  );
};

export default SearchForm;
