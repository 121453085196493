import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import SingularHighlightCard from "../../components/cards/highlights";
import Card from "../cards";
import TitleHeader from "../../components/titleHeader";
import Button from "../../components/button";
import Header from "../header";
import Footer from "../footer";
import api from "../../api";

import {
  Container,
  ContainerRealEstate,
  Content,
  ResultsMessage,
} from "./styles";

const RealestateList = ({ isSingular }) => {
  const navigate = useNavigate();
  const [realestate, setRealEstate] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const building_ids = [
    36399, 18986, 11797, 23096, 57711, 46800, 36421, 49443, 38127, 29733, 28642,
    49635, 42850, 35257, 43576, 61072, 57713, 58690, 28192,
  ];

  const baseData = {
    min_price_per_private_square_meter: 20000,
    finality: ["residential"],
    results_per_page: 12,
  };

  const renderRealEstate = async () => {
    setLoading(true);

    let data = { ...baseData };

    if (isSingular) {
      data = {
        ...data,
        building_ids: building_ids,
        results_per_page: 18,
      };
    }

    try {
      const response = await api.get("/buildings", { params: data });

      if (isSingular) {
        setRealEstate(response.data.buildings);
      } else {
        setRealEstate((realestate) => [
          ...realestate,
          ...response.data.buildings,
        ]);
      }

      setTotalPage(response.data.total_pages);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreRealestate = async () => {
    setDisabled(true);

    try {
      const response = await api.get("/buildings", {
        params: { ...baseData, page: page + 1 },
      });

      setRealEstate((prevRealEstate) => [
        ...prevRealEstate,
        ...response.data.buildings,
      ]);
      setPage(page + 1);
    } catch (error) {
      console.log("Erro ao renderizar mais imóveis: ", error);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    renderRealEstate();
  }, []);

  return (
    <Container>
      <Header />
      <Content>
        <TitleHeader
          title={
            isSingular ? "Destaques Singular Realty" : "Oportunidades especiais"
          }
          subtitle={
            isSingular ? "Imóveis selecionados especialmente para você" : ""
          }
          className="title"
        />

        {loading && <ResultsMessage>CARREGANDO...</ResultsMessage>}

        <ContainerRealEstate isSingular={isSingular}>
          {realestate.map((item, index) => {
            const CardComponent = isSingular ? SingularHighlightCard : Card;
            return (
              <CardComponent
                key={index}
                image={item.default_image["520x280"]}
                location={item.address.area}
                squareMeters={item.min_area}
                parkingSpaces={item.min_parking}
                dormitory={item.min_bedrooms}
                value={item.min_price.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                name={item.name}
                suite={item.min_suites}
                onClick={() => navigate(`/imoveis/${item.id}`)}
              />
            );
          })}
        </ContainerRealEstate>

        {page < totalPage && (
          <Button
            onClick={loadMoreRealestate}
            backgroundColor={isSingular ? "#C29B40" : "#14202E"}
            disabled={disabled}
            icon={false}
          >
            {disabled ? "CARREGANDO..." : "CARREGAR MAIS RESULTADOS"}
          </Button>
        )}
      </Content>
      <Footer />
    </Container>
  );
};

export default RealestateList;
