import styled from "styled-components";

export const Container = styled.div`
  width: 264px;
  height: 180px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 10px rgba(20, 32, 46, 0.2); /* Aumenta levemente no hover */
  }
`;

export const Image = styled.img`
  width: 264px;
  height: 145px;
`;

export const Title = styled.span`
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Playfair Display", sans-serif;
  color: #14202e;
  font-size: 19px;
  font-weight: bold;
`;
