import { Container, Line, Subtitle, Title } from "./styles";

const TitleHeader = ({ title, subtitle, className, color }) => {
  return (
    <Container className={className}>
      <Title style={{ color: color }}>{title}</Title>

      {subtitle && <Subtitle style={{ color: color }}>{subtitle}</Subtitle>}

      <Line />
    </Container>
  );
};

export default TitleHeader;
