import styled from "styled-components";

export const CardContainer = styled.div`
  width: 345px;
  height: 247px;
  border: 1px solid white;
  padding: 27px 21px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;

  @media (max-width: 395px) {
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-family: "Playfair Display", sans-serif;
  font-size: 20px;
  color: white;
  font-weight: bold;
`;

export const Location = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: white;
  font-weight: bold;
  margin-bottom: 14px;
`;

export const InfoText = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: white;
`;

export const ButtonContainer = styled.div`
  margin-top: 33px;
  width: 157px;
`;
