import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";

import Card from "../cards/";
import TitleHeader from "../titleHeader";
import Button from "../button";

import { Container, ContainerRealEstate, Content } from "./styles";

const RealestateHighlights = () => {
  const navigate = useNavigate();
  const [realEstates, setRealEstates] = useState([]);

  const renderRealestate = async () => {
    try {
      const response = await api.get("/buildings", {
        params: {
          min_price_per_private_square_meter: 20000,
          finality: ["residential"],
        },
      });

      setRealEstates(response.data.buildings.slice(0, 4));
    } catch (error) {
      console.error("Erro ao buscar imóveis:", error);
    }
  };

  useEffect(() => {
    renderRealestate();
  }, []);

  return (
    <Container>
      <Content>
        <TitleHeader title="Oportunidades especiais" className="title" />

        <ContainerRealEstate>
          {realEstates.map(
            ({
              id,
              default_image,
              address,
              min_area,
              min_parking,
              min_bedrooms,
              min_price,
              name,
              min_suites,
            }) => (
              <Card
                key={id}
                image={default_image["200x140"]}
                location={address?.area}
                squareMeters={min_area}
                parkingSpaces={min_parking}
                dormitory={min_bedrooms}
                value={min_price.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })}
                name={name}
                suite={min_suites}
                onClick={() => navigate(`/imoveis/${id}`)}
              />
            )
          )}
        </ContainerRealEstate>

        <Button
          backgroundColor="#14202E"
          onClick={() => navigate("/oportunidades-especiais")}
        >
          VEJA MAIS OPÇÕES
        </Button>
      </Content>
    </Container>
  );
};

export default RealestateHighlights;
