import styled from "styled-components";

export const Container = styled.section`
  min-height: 831px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 5px solid #c29b40;
  padding-top: 41px;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1128px;
  height: 100%;

  button {
    margin: 0 auto;
  }

  .title {
    margin-bottom: 33px;
  }

  @media (max-width: 1200px) {
    padding: 20px 20px;
  }
`;

export const ContainerRealEstate = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 55px;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;
