import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.header`
  width: 100%;
  height: 114px;
  background-color: #14202e;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid white;

  @media (max-width: 1000px) {
    height: 49px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerInfo = styled.div`
  border-bottom: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 1200px) {
    padding: 0px 20px;
  }
`;

export const LogoMobile = styled.img`
  display: none;
  width: 120px;
  height: auto;
  cursor: pointer;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

export const Info = styled.div`
  width: 1128px;
  height: 48px;
  display: flex;
  justify-content: end;
  align-items: center;
`;

export const SearchBar = styled.img`
  cursor: pointer;
  margin-right: 50px;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const SearchMobile = styled.div`
  display: none;
  margin-right: 20px;

  @media (max-width: 600px) {
    display: flex;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
  gap: 16px;
  margin-right: 46px;

  svg {
    cursor: pointer;
    transition: color 0.3s ease;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const MenuMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`;

export const List = styled.ul`
  display: flex;
  list-style: none;
  gap: 17px;
  align-items: center;
  font-size: 13px;
  color: white;
  font-family: "Montserrat", sans-serif;

  @media (max-width: 600px) {
    display: none;
  }
`;

export const Item = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  color: white;
`;

export const Search = styled.div`
  display: flex;
  height: 66px;
  justify-content: space-between;
  width: 1128px;

  @media (max-width: 1200px) {
    padding: 0px 20px;
    width: 100%;
  }

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Logo = styled.img`
  height: 100%;
  width: 165px;
  cursor: pointer;
`;

export const MenuSeacrh = styled.div`
  position: absolute;
  top: 114px;

  width: 100%;
  z-index: 2;

  @media (max-width: 1000px) {
    top: 49px;
  }
`;
