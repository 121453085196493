import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
`;

export const ContainerCarousel = styled.figure`
  .title {
    position: absolute;
    bottom: 134px;
    left: 50%;
    top: 45px;
    transform: translateX(-50%);
    max-width: 80%;
    z-index: 1;
    height: auto;
    padding-left: 200px;
    height: 200px;

    h1 {
      color: white;
      font-size: 50px;
      font-weight: 100;
      font-family: "Playfair Display", sans-serif;

      @media (max-width: 920px) {
        font-size: 40px;
      }

      @media (max-width: 425px) {
        font-size: 30px;
      }
    }

    span {
      color: white;
      font-family: "Playfair Display", sans-serif;
      font-size: 30px;
      font-style: italic;

      @media (max-width: 920px) {
        font-size: 20px;
      }
    }

    div {
      width: 304px;

      @media (max-width: 425px) {
        width: 93px;
        margin: 0 auto;
      }
    }

    @media (max-width: 800px) {
      padding-left: 0px;
      transform: translateX(0);
      left: 0;
      margin-left: 29px;
    }

    @media (max-width: 425px) {
      margin: 0 auto;
      max-width: none;
      width: 100%;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ContainerSideBar = styled.div`
  @media (max-width: 800px) {
    display: none;
  }
`;

export const ContainerSideBarMobile = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
  }
`;
