import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../../api";

import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Button from "../../button";
import ImageModal from "../../imageModal";

import {
  Container,
  Location,
  RealestateInfo,
  RealestateDetail,
  TypeRealestate,
  Infos,
  Value,
  ImageRealestate,
  ContainerMoreInformations,
  Title,
  ContentMoreInformations,
  Features,
  FeatureText,
} from "./styles";

import IconSquareMeters from "../../../assets/icons/squareMeters.svg";
import IconBed from "../../../assets/icons/bed.svg";
import IconParkingSpaces from "../../../assets/icons/parking_space.svg";
import IconBathroom from "../../../assets/icons/bathroom.svg";

import Joya1 from "../../../assets/realestate/joya/ITU_01_Fachada_Itu_EF2_11zon.webp";
import Joya2 from "../../../assets/realestate/joya/ITU_03_Portaria_EF2_11zon.webp";
import Joya3 from "../../../assets/realestate/joya/ITU_04_Lounge_EF_11zon.webp";
import Joya4 from "../../../assets/realestate/joya/ITU_05_Piscina_Solarium_EF2_11zon.webp";
import Joya5 from "../../../assets/realestate/joya/ITU_06_Piscina_Raia_EF2_11zon.webp";
import Joya6 from "../../../assets/realestate/joya/ITU_07_Piscina_Voo_Passaro_EF2_11zon.webp";
import Joya7 from "../../../assets/realestate/joya/ITU_08_Bosque_EF2_11zon.webp";
import Joya8 from "../../../assets/realestate/joya/ITU_09_Lobby_Clausura_EF_11zon.webp";
import Joya9 from "../../../assets/realestate/joya/ITU_10_Delivery_EF_11zon.webp";
import Joya10 from "../../../assets/realestate/joya/ITU_11_Conceitual_Fachada_EF_11zon.webp";
import Joya11 from "../../../assets/realestate/joya/ITU_12_Academia_A_EF3_11zon.webp";
import Joya12 from "../../../assets/realestate/joya/ITU_13_Academia_B_EF3_11zon.jpeg";
import Joya13 from "../../../assets/realestate/joya/ITU_14_Festas_EF_11zon.jpeg";
import Joya14 from "../../../assets/realestate/joya/ITU_15_Gourmet_EF2_11zon.jpeg";
import Joya15 from "../../../assets/realestate/joya/ITU_16_Terraco_Gourmet_EF_11zon.jpeg";
import Joya16 from "../../../assets/realestate/joya/ITU_17_Detalhe_Baixo_para_Cima_EF2_11zon.jpeg";
import Joya17 from "../../../assets/realestate/joya/ITU_18_Spa_EF_11zon.jpeg";
import Joya18 from "../../../assets/realestate/joya/ITU_19_Sauna_EF_11zon.webp";
import Joya19 from "../../../assets/realestate/joya/ITU_20_Massagem_EF_11zon.webp";
import Joya20 from "../../../assets/realestate/joya/ITU_21_Garagem_EF_11zon.webp";
import Joya21 from "../../../assets/realestate/joya/ITU_22_Bicicletario_EF_11zon.webp";
import Joya22 from "../../../assets/realestate/joya/ITU_23_Fotomontagem_EF_11zon.webp";

function InfosRealestate({
  latitude,
  longitude,
  name,
  location,
  value,
  parkingSpaces,
  squareMeters,
  bedroom,
  description,
  suite,
  bathrooms,
  stage,
  launch_date,
  opening_date,
  total_units,
  features,
}) {
  const [images, setImages] = useState([]);
  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedImage: "",
    index: 0,
  });

  const { id } = useParams();

  const imagesJoya = [
    Joya1,
    Joya2,
    Joya3,
    Joya4,
    Joya5,
    Joya6,
    Joya7,
    Joya8,
    Joya9,
    Joya10,
    Joya11,
    Joya12,
    Joya13,
    Joya14,
    Joya15,
    Joya16,
    Joya17,
    Joya18,
    Joya19,
    Joya20,
    Joya21,
    Joya22,
  ];

  const renderModal = (index) => {
    setModalState({
      selectedImage: images[index]["2280x1800"] || images[index],
      index,
      isOpen: true,
    });
  };

  const closeModal = () => {
    setModalState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const handleNavigate = (newIndex) => {
    if (newIndex < 0) {
      setModalState((prevState) => ({
        ...prevState,
        index: images.length - 1,
      }));
      return;
    }
    if (newIndex >= images.length) {
      setModalState((prevState) => ({
        ...prevState,
        index: 0,
      }));
      return;
    }

    setModalState((prevState) => ({
      ...prevState,
      index: newIndex,
    }));
  };

  const fetchImages = async () => {
    if (id === "1") {
      setImages(imagesJoya.reverse());
      return;
    }

    try {
      const response = await api.get(`/buildings/${id}/images`, {
        params: { dimensions: ["2280x1800"] },
      });
      setImages(response.data.images);
    } catch (error) {
      console.log("Erro na requisição: ", error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [id]);

  return (
    <Container>
      <Carousel
        key={images.length}
        showThumbs={false}
        className="carousel"
        infiniteLoop={true}
        showStatus={false}
      >
        {images.map((image, index) => (
          <ImageRealestate
            key={index}
            style={{
              backgroundImage: `url("${image["2280x1800"] || image}")`,
            }}
            onClick={() => renderModal(index)}
          />
        ))}
      </Carousel>

      {modalState.isOpen && (
        <ImageModal
          images={images}
          index={modalState.index}
          onClose={closeModal}
          onNavigate={handleNavigate}
        />
      )}

      <RealestateInfo>
        <TypeRealestate>
          <div>
            <h2>{name}</h2>
            <span>{location}</span>
          </div>

          <Button
            backgroundColor="#25D366"
            icon="whats"
            onClick={() =>
              window.open(
                `https://wa.me/5511989217386?text=Olá, gostaria de saber mais sobre o imóvel https://singularrealty.com.br/imoveis/${id}`,
                "_blank"
              )
            }
          >
            Agende uma visita
          </Button>
        </TypeRealestate>

        <Value>
          {parseFloat(value.replace(/[^\d,.-]/g, "").replace(",", ".")) !==
            0.1 && <h1>{value}</h1>}
        </Value>

        <RealestateDetail>
          <Infos>
            <div>
              <img src={IconSquareMeters} alt="Área" />
              <span>{squareMeters} m²</span>
            </div>

            {suite !== 0 && (
              <div>
                <img src={IconBed} alt="Suíte" />
                <span>{suite} suíte(s)</span>
              </div>
            )}

            {parkingSpaces !== 0 && (
              <div>
                <img src={IconParkingSpaces} alt="Vaga" />
                <span>{parkingSpaces} vaga(s)</span>
              </div>
            )}

            {bedroom !== 0 && (
              <div>
                <img src={IconBed} alt="Quartos" />
                <span>{bedroom} dormitório(s)</span>
              </div>
            )}
            {bathrooms !== 0 && (
              <div>
                <img src={IconBathroom} alt="Banheiro" />
                <span>{bathrooms} banheiro(s)</span>
              </div>
            )}
          </Infos>

          <Button
            backgroundColor="#25D366"
            icon="whats"
            onClick={() =>
              window.open(
                `https://wa.me/5511989217386?text=Olá, gostaria de saber mais sobre o imóvel https://singularrealty.com.br/imoveis/${id}`,
                "_blank"
              )
            }
          >
            Agende uma visita
          </Button>
          <p>{description}</p>
        </RealestateDetail>
      </RealestateInfo>

      <ContainerMoreInformations>
        <ContentMoreInformations>
          <Title>Outras Informações</Title>
          <div>
            <h1>Estágio: </h1>
            <span>{stage}</span>
          </div>
          <div>
            <h1>Lançamento: </h1>
            <span>{launch_date}</span>
          </div>
          <div>
            <h1>Entrega: </h1>
            <span>{opening_date}</span>
          </div>
          <div>
            <h1>Total de unidades: </h1>
            <span>{total_units}</span>
          </div>
        </ContentMoreInformations>

        <Features>
          {features.map((feature, index) => (
            <FeatureText key={index}>{feature}</FeatureText>
          ))}
        </Features>
      </ContainerMoreInformations>

      <Location>
        <APIProvider apiKey="AIzaSyCCNTdgrQX8NPjhxyUUVGDPcyL9nnO1jyY">
          <Map
            defaultZoom={13}
            defaultCenter={{ lat: latitude, lng: longitude }}
            mapId="891295543fa9f0b0"
          >
            <AdvancedMarker position={{ lat: latitude, lng: longitude }} />
          </Map>
        </APIProvider>
      </Location>
    </Container>
  );
}

export default InfosRealestate;
