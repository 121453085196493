import ResetCSS from "./reset";
import Routes from "./pages/routes";

function App() {
  return (
    <>
      <ResetCSS />
      <Routes />
    </>
  );
}

export default App;
