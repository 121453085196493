import { Container, ContainerCards, Content } from "./styles";
import { useNavigate } from "react-router-dom";

import TitleHeader from "../titleHeader";
import CategoryCard from "../cards/category";

import typerealestateOne from "../../assets/typeRealestate/typerealestateOne.jpg";
import typerealestateTwo from "../../assets/typeRealestate/typerealestateTwo.jpg";
import typerealestateThree from "../../assets/typeRealestate/typerealestateThree.jpg";
import typerealestateFor from "../../assets/typeRealestate/typerealestateFor.jpg";
import typerealestateFive from "../../assets/typeRealestate/typerealestateFive.jpg";
import typerealestateSix from "../../assets/typeRealestate/typerealestateSix.jpg";
import typerealestateSeven from "../../assets/typeRealestate/typerealestateSeven.jpg";
import typerealestateEight from "../../assets/typeRealestate/typerealestateEight.jpg";

const FindYourRealestate = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <TitleHeader title="Encontre seu imóvel ideal" className="title" />

        <ContainerCards>
          <CategoryCard image={typerealestateOne} title="Lançamentos" />
          <CategoryCard
            image={typerealestateTwo}
            title="Obras"
            onClick={() => navigate("/imoveis/categoria/obras")}
          />
          <CategoryCard
            image={typerealestateThree}
            title="Pronto para Morar"
            onClick={() => navigate("/imoveis/categoria/pronto-para-morar")}
          />
          <CategoryCard image={typerealestateFor} title="Praia" />
          <CategoryCard image={typerealestateFive} title="Campo" />
          <CategoryCard
            image={typerealestateSix}
            title="Internacional"
            onClick={() =>
              window.open("https://realestate4me.com.br/", "_blank")
            }
          />
          <CategoryCard
            image={typerealestateSeven}
            title="Coberturas"
            onClick={() => navigate("/imoveis/categoria/coberturas")}
          />
          <CategoryCard image={typerealestateEight} title="Usados" />
        </ContainerCards>
      </Content>
    </Container>
  );
};

export default FindYourRealestate;
