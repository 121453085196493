import Footer from "../../components/footer";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";

import { Container, Content, Description } from "./styles";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Header />
      <Content>
        <TitleHeader title="Política de privacidade" className="title" />
        <Description>
          A Singular Realty assume o compromisso de respeitar a legislação
          vigente relativa à proteção de dados pessoais e a não ceder, sob
          qualquer forma ou condição, estas informações a outras entidades.{" "}
          <br /> <br /> Nós coletamos os seguintes tipos de dados:
          <br /> <br /> Dados Pessoais; <br />
          Dados de Imóveis.
          <br /> <br /> Utilizamos os dados coletados exclusivamente para o
          contato direto com o usuário, por meio de ligação telefônica, e-mail
          ou WhatsApp, com o objetivo de enviar comunicados relacionados à
          Singular Realty e oferecer produtos e serviços de interesse.
          <br /> <br /> Em nenhuma hipótese, a Singular Realty compartilha Dados
          Pessoais ou de Imóveis com parceiros ou terceiros sem o consentimento
          prévio e expresso do usuário.
          <br /> <br /> Qualquer pessoa que se cadastrar em qualquer site ou
          landing page da Singular Realty terá seus dados protegidos por
          sistemas de segurança. Caso deseje acessar ou remover seus dados do
          sistema, basta enviar um e-mail para contato@singular.com.br.
          <br /> <br />
          Utilizamos cookies e tecnologias semelhantes, como pixels e tags, para
          garantir que os serviços prestados e oferecidos atendam ao mais alto
          padrão de desempenho esperado pelo usuário, em ações como marketing e
          estudo do comportamento do usuário nos sites da Singular Realty.
          <br /> <br />
          Todos os cookies coletados jamais serão utilizados para qualquer
          propósito diferente dos expressamente previstos nesta Política de
          Privacidade. <br /> <br />A Singular Realty também se compromete a
          adotar todas as medidas de segurança necessárias para garantir a
          proteção dos dados pessoais dos usuários contra uso indevido ou acesso
          não autorizado. <br /> <br />A Singular Realty poderá alterar esta
          política de privacidade e segurança de dados a qualquer momento. Todas
          as alterações serão publicadas neste espaço, portanto, solicitamos a
          leitura periódica desta Política como meio de se informar sobre como a
          Singular Realty coleta, trata e protege suas informações. <br />{" "}
          <br />
          Caso tenha qualquer dúvida ou sugestão sobre esta Política de
          Privacidade e Segurança de Dados, escreva para:
          contato@singularrealty.com.br.
        </Description>
      </Content>
      <Footer />
    </Container>
  );
};

export default PrivacyPolicy;
