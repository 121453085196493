import Footer from "../../components/footer";
import Header from "../../components/header";
import Sidebar from "../../components/sideBar";
import TitleHeader from "../../components/titleHeader";
import Carousel from "../../components/carousel";
import SingularRealtyHighlights from "../../components/singularRealtyHighlights";
import FindYourRealestate from "../../components/findYourRealestate";
import RealestateHighlights from "../../components/realestateHighlights";

import {
  Container,
  ContainerCarousel,
  ContainerSideBar,
  ContainerSideBarMobile,
} from "./styles";

import Joya from "../../assets/realestate/joya/realestate_11zon.webp";
import Etmo from "../../assets/realestate/etmo/etmo.webp";
import Panorama from "../../assets/realestate/panorama/panorama.webp";
import Vilanova from "../../assets/realestate/vilanova/vilanova.webp";

const realestates = [
  {
    id: 1,
    title: "Apartamento",
    location: "Jardins",
    size: 81.19,
    garage: 1,
    suite: 2,
    bedroom: 3,
    image: Joya,
  },
  {
    id: 57676,
    title: "Apartamento",
    location: "Vila Nova Conceição",
    size: "253 a 409",
    garage: "3 a 4",
    suite: 3,
    bedroom: 3,
    image: Vilanova,
  },
  {
    id: 61169,
    title: "Apartamento",
    location: "Cerqueira César",
    size: 120,
    garage: 1,
    suite: 2,
    bedroom: 2,
    image: Etmo,
  },
  {
    id: 61277,
    title: "Apartamento",
    location: "Moema",
    size: 248.53,
    garage: 4,
    suite: 4,
    bedroom: 4,
    image: Panorama,
  },
];

const Home = () => {
  return (
    <Container>
      <section>
        <ContainerSideBar>
          <Sidebar />
        </ContainerSideBar>

        <ContainerSideBarMobile>
          <Header />
        </ContainerSideBarMobile>

        <ContainerCarousel>
          <TitleHeader
            title="Imóveis singulares"
            subtitle="para uma vida extraordinária"
            className="title"
          />
          <Carousel realestates={realestates} />
        </ContainerCarousel>
      </section>

      <SingularRealtyHighlights />
      <FindYourRealestate />
      <RealestateHighlights />
      <Footer />
    </Container>
  );
};

export default Home;
