import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #14202e;
`;

export const FormContent = styled.div`
  max-width: 1128px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px;
`;

export const Heading = styled.span`
  font-size: 27px;
  font-family: "Playfair Display", sans-serif;
  color: white;
  margin-top: 25px;
`;

export const Form = styled.form`
  margin-top: 17px;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-bottom: 31px;

  @media (max-width: 1000px) {
    flex-direction: column;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  @media (max-width: 600px) {
    display: flex;
    align-items: start;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  gap: 8px;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const InputLabel = styled.label`
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: white;
`;

export const DropdownSelect = styled.select`
  height: 39px;
  border: none;
  font-size: 15px;
  border-radius: 5px;
  padding-left: 10px;

  option {
    font-family: "Montserrat", sans-serif;
  }
`;

export const TextInput = styled.input`
  height: 39px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  padding-left: 10px;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
`;

export const CheckboxOptionLabel = styled.label`
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  color: white;
  width: auto;

  input {
    margin-right: 5px;
    width: auto;
    height: auto;
  }
`;
