import { useState } from "react";
import { useNavigate } from "react-router-dom";

import SearchTwo from "../searchTwo";
import SideBarMobile from "../sideBarMobile";

import {
  Container,
  ContainerInfo,
  Content,
  Info,
  Item,
  List,
  Logo,
  LogoMobile,
  MenuMobile,
  MenuSeacrh,
  Search,
  SearchBar,
  SearchMobile,
  SocialIcons,
} from "./styles";

import {
  FaInstagram,
  FaFacebook,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";

import { LuMail } from "react-icons/lu";
import { IoMenu, IoSearch } from "react-icons/io5";

import ButtonSearch from "../../assets/buttonSearch.svg";
import LogoSingular from "../../assets/logo_white.svg";

const Header = () => {
  const navigate = useNavigate();
  const [display, setDisplay] = useState(false);
  const [sideBarShow, setSideBarShow] = useState(false);

  const showSideBar = () => {
    setSideBarShow(!sideBarShow);
  };

  const toggleDisplay = () => {
    setDisplay((prevDisplay) => !prevDisplay);
  };

  return (
    <Container>
      <Content>
        <ContainerInfo>
          <LogoMobile
            src={LogoSingular}
            alt="logo"
            onClick={() => navigate("/")}
          />

          <Info>
            <SearchBar
              src={ButtonSearch}
              alt="buscar_imóveis"
              onClick={toggleDisplay}
            />

            <SearchMobile>
              <IoSearch size={16} color="#E8EAED" onClick={toggleDisplay} />
            </SearchMobile>

            <SocialIcons>
              <a
                aria-label="instagram singular realty"
                href="https://www.instagram.com/singular.realty/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram color="#E8EAED" />
              </a>

              <a
                aria-label="facebook singular realty"
                href="https://www.facebook.com/singular.realty.br/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaFacebook color="#E8EAED" />
              </a>

              <a
                aria-label="linkedin singular realty"
                href="https://www.linkedin.com/company/singular-realty/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <FaLinkedin color="#E8EAED" />
              </a>
              <a
                aria-label="whatsapp"
                href="https://wa.me/5511989217386"
                rel="noopener noreferrer"
                target="blank"
              >
                <FaWhatsapp color="#E8EAED" />
              </a>

              <a
                aria-label="email singular realty"
                rel="noopener noreferrer"
                href="mailto:contato@singularrealty.com.br"
              >
                <LuMail color="#E8EAED" />
              </a>
            </SocialIcons>

            <MenuMobile>
              <IoMenu size={25} color="#E8EAED" onClick={showSideBar} />
              {sideBarShow && (
                <SideBarMobile
                  visible={sideBarShow}
                  setVisible={setSideBarShow}
                />
              )}
            </MenuMobile>
            <List>
              <Item to="/sobre-nos">Sobre Nós</Item>
              <Item
                onClick={() =>
                  document
                    .querySelector("footer")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                Contato
              </Item>
            </List>
          </Info>
        </ContainerInfo>

        <Search>
          <Logo alt="logo" src={LogoSingular} onClick={() => navigate("/")} />
          <List>
            <Item>LANÇAMENTOS</Item>
            <Item to="/imoveis/categoria/obras">OBRAS</Item>
            <Item to="/imoveis/categoria/pronto-para-morar">
              PRONTO PARA MORAR
            </Item>
            <Item>PRAIA</Item>
            <Item>CAMPO</Item>
            <Item
              onClick={() =>
                window.open("https://realestate4me.com.br/", "_blank")
              }
            >
              INTERNACIONAL
            </Item>
            <Item to="/imoveis/categoria/coberturas">COBERTURAS</Item>
            <Item>USADOS</Item>
          </List>
        </Search>
      </Content>
      <MenuSeacrh style={{ display: display ? "block" : "none" }}>
        <SearchTwo onSearch={toggleDisplay} />
      </MenuSeacrh>
    </Container>
  );
};

export default Header;

//  display: ${(props) => (props.display ? "block" : "none")};
