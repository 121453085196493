import React, { useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import styled from "styled-components";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  position: relative;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  padding: 10px;

  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
`;

const PreviousButton = styled(Button)`
  left: 20px;
`;

const NextButton = styled(Button)`
  right: 20px;
`;

const CloseModal = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

const ImageModal = ({ images, index, onClose, onNavigate }) => {
  const handlePrevious = () => {
    onNavigate(index - 1);
  };

  const handleNext = () => {
    onNavigate(index + 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowLeft") {
      handlePrevious();
    } else if (event.key === "ArrowRight") {
      handleNext();
    } else if (event.key === "Escape") {
      onClose();
    }
  };

  const handleClickOutside = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [index]);

  return (
    <ModalContainer onClick={handleClickOutside}>
      <ModalImage
        src={images[index]["2280x1800"] || images[index]}
        alt="imóvel"
      />
      <PreviousButton onClick={handlePrevious}>❮</PreviousButton>
      <NextButton onClick={handleNext}>❯</NextButton>

      <CloseModal onClick={onClose}>
        <IoMdClose size={30} color="#fff" />
      </CloseModal>
    </ModalContainer>
  );
};

export default ImageModal;
