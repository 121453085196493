import {
  Container,
  ContentInfos,
  Image,
  Info,
  Location,
  Name,
  Value,
} from "./styles";

const SingularHighlightCard = ({
  image,
  name,
  location,
  value,
  squareMeters,
  parkingSpaces,
  dormitory,
  onClick,
  suite,
}) => {
  return (
    <Container onClick={onClick}>
      <Image alt="imovel" style={{ backgroundImage: `url("${image}")` }} />

      <ContentInfos>
        <Name>{name}</Name>
        <Location>{location}</Location>

        <Info>{squareMeters} m²</Info>

        {parkingSpaces !== 0 && <Info>{parkingSpaces} vaga(s)</Info>}
        {suite !== 0 && <Info>{suite} suíte(s)</Info>}
        {dormitory !== 0 && <Info>{dormitory} dormitório(s)</Info>}

        <Value>{value}</Value>
      </ContentInfos>
    </Container>
  );
};

export default SingularHighlightCard;
