import styled from "styled-components";

export const Container = styled.div`
  width: 360px;
  height: 530px;
  display: flex;
  flex-direction: column;
  background-color: white;
  transition: box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 6px 10px rgba(194, 155, 64, 0.2);
  }

  @media (max-width: 390px) {
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 18px;
`;

export const Image = styled.div`
  height: 234px;
  margin-bottom: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  border-bottom: 5px solid #14202e;
`;

export const Name = styled.span`
  font-family: "Playfair Display", sans-serif;
  font-size: 32px;
  color: #14202e;
  margin-bottom: 25px;
`;

export const Location = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: #14202e;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 14px;
`;

export const Info = styled.span`
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
`;

export const Value = styled.span`
  font-family: "Playfair Display", sans-serif;
  color: #c29b40;
  margin-top: 25px;
  font-size: 20px;
  font-weight: bold;
`;
