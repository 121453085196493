import styled from "styled-components";

export const FormContainer = styled.form`
  background-color: #14202e;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 390px;
  color: white;
  height: 90vh;

  #swal2-title {
    font-family: "Montserrat", sans-serif;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 27px;
  font-family: "Playfair Display", sans-serif;
`;

export const Label = styled.label`
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
`;

export const Input = styled.input`
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 14px;
  height: 39px;
  padding-left: 16px;
`;

export const Select = styled.select`
  border: none;
  font-size: 15px;
  border-radius: 5px;
  height: 39px;
  padding-left: 16px;

  option {
    font-family: "Montserrat", sans-serif;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

export const CheckboxLabel = styled.label`
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: "Montserrat", sans-serif;

  input {
    margin-right: 5px;
    border: 1px solid white;
    width: 20px;
    height: 20px;
  }
`;
