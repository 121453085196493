import Footer from "../../components/footer";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import { Container, Content, Description } from "./styles";

const AboutUs = () => {
  return (
    <Container>
      <Header />
      <Content>
        <TitleHeader
          title="Singular Realty"
          subtitle="Imóveis singulares para uma vida extraordinária."
          className="title"
        />

        <Description>
          Especializada na intermediação de imóveis de alto padrão, a{" "}
          <strong>Singular Realty</strong> oferece um portfólio diversificado
          que inclui <strong>mais de 170 construtoras de renome</strong>, com
          imóveis localizados nos melhores bairros de São Paulo e outras cidades
          estratégicas. <br />
          <br />
          Atuamos com diversas categorias de imóveis como lançamentos, obras em
          andamento, imóveis prontos para morar, imóveis de praia, campo,
          internacionais, coberturas e imóveis usados.
          <br />
          <br /> Na <strong>Singular Realty</strong>, nosso compromisso vai além
          da transação. Oferecemos um serviço personalizado e completo, que
          acompanha você desde a busca pelo imóvel ideal até o pós-venda. Nossa
          missão é garantir conforto, praticidade e uma experiência exclusiva,
          tanto para vendedores quanto para compradores e investidores.
          <br />
          <br />
          Transformamos a jornada imobiliária em algo extraordinário, sempre
          focando nas necessidades específicas de cada cliente, com imóveis que
          refletem estilo e sofisticação.
        </Description>
      </Content>
      <Footer />
    </Container>
  );
};

export default AboutUs;
