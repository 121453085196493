import React from "react";
import { useNavigate } from "react-router-dom";

import SideBarItem from "./sideBarItem";

import { FaTimes } from "react-icons/fa";

import { Container, Content, Navigation, Info } from "./styles";

const SideBarMobile = ({ visible, setVisible }) => {
  const navigate = useNavigate();

  const closeSideBar = () => {
    setVisible(false); // Fecha a sidebar
  };

  const handleNavLink = (event) => {
    event.preventDefault();
    const sectionId = event.target.getAttribute("href");
    const section = document.querySelector(sectionId);

    if (section) {
      closeSideBar();
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigate = (path) => {
    closeSideBar(); // Fecha a sidebar primeiro

    setTimeout(() => {
      navigate(path); // Navega após fechar a sidebar
    }, 300);
  };

  return (
    <Container sidebar={visible ? true : false}>
      <FaTimes onClick={closeSideBar} />
      <Content>
        <Navigation>
          <SideBarItem>LANÇAMENTOS</SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem
            onClick={() => handleNavigate("/imoveis/categoria/obras")}
          >
            OBRAS
          </SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem
            onClick={() =>
              handleNavigate("/imoveis/categoria/pronto-para-morar")
            }
          >
            PRONTO PARA MORAR
          </SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem>PRAIA</SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem>CAMPO</SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem
            onClick={() =>
              window.open("https://realestate4me.com.br/", "_blank")
            }
          >
            INTERNACIONAL
          </SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem
            onClick={() => handleNavigate("/imoveis/categoria/coberturas")}
          >
            COBERTURAS
          </SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem>USADOS</SideBarItem>
        </Navigation>
      </Content>

      <Info>
        <Navigation>
          <SideBarItem onClick={() => handleNavigate("/sobre-nos")}>
            Sobre Nós
          </SideBarItem>
        </Navigation>
        <Navigation>
          <SideBarItem href="#footer" onClick={(event) => handleNavLink(event)}>
            Contato
          </SideBarItem>
        </Navigation>
      </Info>
    </Container>
  );
};

export default SideBarMobile;
