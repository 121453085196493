import styled from "styled-components";

export const Container = styled.div`
  max-width: 1128px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  z-index: 1;

  @media (max-width: 1200px) {
    padding: 20px 20px;
  }

  .carousel {
    height: 397px;
    margin-bottom: 22px;
    border-bottom: 5px solid #14202e;
  }
`;

export const TypeRealestate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;

  h2 {
    font-family: "Playfair Display", sans-serif;
    font-size: 36px;
    font-weight: bold;
    color: #14202e;
    margin-bottom: 10px;
  }

  span {
    font-family: "Montserrat", sans-serif;
    color: #14202e;
    font-size: 16px;
  }

  button {
    @media (max-width: 600px) {
      display: none;
    }
  }
`;

export const Value = styled.div`
  font-family: "Playfair Display", sans-serif;
  font-size: 36px;
  font-weight: bold;
  color: #c29b40;
  margin-bottom: 40px;

  @media (max-width: 375px) {
    margin-bottom: 20px;
  }

  @media (max-width: 500px) {
    font-size: 20px;
  }
`;

export const Infos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  img {
    height: 30px;
    width: 30px;
  }

  @media (max-width: 375px) {
    font-size: 13px;
  }
`;

export const Location = styled.div`
  height: 423px;
`;

export const RealestateInfo = styled.div`
  margin-bottom: 32px;
`;

export const RealestateDetail = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #14202e;
  display: flex;
  justify-content: space-between;

  p {
    max-width: 648px;
    width: 100%;
  }

  button {
    display: none;

    @media (max-width: 600px) {
      display: flex;
      margin: 20px 0 20px;
    }
  }

  @media (max-width: 1030px) {
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap: 10px;
  }
`;

export const ContainerMoreInformations = styled.div`
  margin-bottom: 30px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  color: #14202e;
  margin-bottom: 10px;
  font-family: "Playfair Display", sans-serif;
  font-weight: bold;
`;

export const ContentMoreInformations = styled.div`
  div {
    display: flex;
    gap: 5px;
  }

  h1 {
    font-size: 16px;
  }
`;

export const Features = styled.div`
  width: 70%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const FeatureText = styled.div`
  background-color: #f1e6cb;
  display: flex;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  height: 30px;
  font-size: 13px;
  padding: 10px;
`;

export const ImageRealestate = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 397px;
`;
