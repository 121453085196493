import { Container, Image, Title } from "./styles";

const CategoryCard = ({ image, title, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Image src={image} alt="categoria de imóvel" />
      <Title>{title}</Title>
    </Container>
  );
};

export default CategoryCard;
