import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Card from "../../components/cards";
import Header from "../../components/header";
import TitleHeader from "../../components/titleHeader";
import Button from "../../components/button";
import Footer from "../../components/footer";
import api from "../../api";

import {
  Container,
  ContainerRealEstate,
  Content,
  ResultsMessage,
} from "./styles";

const RealestateCategory = () => {
  const navigate = useNavigate();

  const { name } = useParams();

  const [realestate, setRealEstate] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const baseData = {
    min_price_per_private_square_meter: 20000,
    finality: ["residential"],
    results_per_page: 12,
  };

  const categoryMapping = {
    "pronto-para-morar": { status: ["ready"] },
    obras: { status: ["under_construction"] },
    coberturas: {
      type: ["penthouse_duplex", "penthouse", "duplex", "triplex"],
    },
  };

  const data = {
    ...baseData,
    ...(categoryMapping[name] || {}),
  };

  const formatName = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).replace(/-/g, " ");
  };

  const renderRealestate = async () => {
    setLoading(true);
    setRealEstate([]);

    try {
      const response = await api.get("/buildings", {
        params: data,
      });

      setPage(response.data.page);
      setTotalPage(response.data.total_pages);
      setRealEstate(response.data.buildings);
    } catch (error) {
      console.log("Erro: ", error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreRealestates = async () => {
    setDisabled(true);

    try {
      const response = await api.get("/buildings", {
        params: { ...data, page: page + 1 },
      });

      setRealEstate((realestate) => [
        ...realestate,
        ...response.data.buildings,
      ]);

      setPage(page + 1);
    } catch (error) {
      console.log("Erro na requisição: ", error);
    } finally {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (name === "internacional") {
      const script = document.createElement("script");
      script.src = "https://assets.newestateonly.com/iframe-loader/load.js";
      script.async = true;
      script.setAttribute("data-neokey", "65bb60b46e952534aa909262");
      script.setAttribute("data-neolang", "pt");

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    renderRealestate();
  }, [name]);

  return (
    <Container>
      <Header />
      <Content>
        <TitleHeader title={`${formatName(name)}`} className="title" />

        {loading && <ResultsMessage>CARREGANDO...</ResultsMessage>}

        {name === "internacional" ? (
          <>
            <iframe
              id="NEOiframe"
              style={{ width: "100%", height: "100vh", border: "none" }}
              title="Imóveis"
            ></iframe>
          </>
        ) : (
          <>
            <ContainerRealEstate>
              {realestate &&
                realestate.map((realestate, index) => {
                  return (
                    <Card
                      image={realestate.default_image["520x280"]}
                      location={realestate.address.area}
                      squareMeters={realestate.min_area}
                      parkingSpaces={realestate.min_parking}
                      dormitory={realestate.min_bedrooms}
                      value={realestate.min_price.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                      name={realestate.name}
                      suite={realestate.min_suites}
                      onClick={() => navigate(`/imoveis/${realestate.id}`)}
                      key={index}
                    />
                  );
                })}
            </ContainerRealEstate>

            {!loading && page < totalPage && (
              <Button
                onClick={loadMoreRealestates}
                disabled={disabled}
                icon={false}
                backgroundColor="#14202E"
              >
                {disabled ? "CARREGANDO..." : "CARREGAR MAIS RESULTADOS"}
              </Button>
            )}
          </>
        )}
      </Content>
      <Footer />
    </Container>
  );
};

export default RealestateCategory;
