import styled from "styled-components";

export const Container = styled.section`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;

  @media (max-width: 950px) {
    height: 100%;
  }
`;

export const Content = styled.div`
  max-width: 1128px;
  width: 100%;
  height: 100%;
  padding: 41px 0 50px 0;
  min-height: 500px;

  button {
    margin: 0 auto;
  }

  .title {
    margin-bottom: 34px;
  }

  @media (max-width: 1200px) {
    padding: 20px 20px;
  }
`;

export const ContainerRealEstate = styled.div`
  display: grid;
  grid-template-columns: ${({ isSingular }) =>
    isSingular ? "repeat(3, 1fr)" : "repeat(4, 1fr)"};
  margin-bottom: 50px;
  gap: 20px;

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ResultsMessage = styled.div`
  font-size: 18px;
  width: 100%;
  font-family: "Montserrat", sans-serif;
`;
