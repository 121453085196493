import { Container } from "./styles";

import { IoIosAdd } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";

const Button = ({
  icon = "default",
  children,
  backgroundColor = "#C29B40",
  onClick,
  disabled,
}) => {
  return (
    <Container
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon === "default" && <IoIosAdd color="white" size={20} />}
      {icon === "whats" && <FaWhatsapp color="white" size={20} />}

      {children}
    </Container>
  );
};

export default Button;
