import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Content = styled.section`
  max-width: 1128px;
  width: 100%;
  height: 100%;
  padding: 41px 20px 82px 20px;

  .title {
    margin-bottom: 39px;
  }
`;

export const Description = styled.p`
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
`;
