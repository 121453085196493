import axios from "axios";

const access_token = "VtRTW_DF2OdhFMfdttTT8xcIWxC2uvXLf2bFRUkKOGc";

const api = axios.create({
  baseURL: "https://www.orulo.com.br/api/v2",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${access_token}`,
  },
});

export default api;
