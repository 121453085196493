import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import TitleHeader from "../titleHeader";
import Button from "../button";
import SingularHighlightCard from "../cards/highlights";
import api from "../../api";

import { Container, ContainerRealEstate, Content } from "./styles";

import Joya from "../../assets/realestate/joya/realestate_11zon.webp";

const property = {
  id: 1,
  name: "Joya Jardins",
  neighborhood: "Jardins",
  squareMeters: "81.19",
  price: 2395104.93,
  suites: 2,
  bathrooms: 3,
  parkingSpots: 1,
  default_image: {
    "520x280": Joya,
  },
};

const SingularRealtyHighlights = () => {
  const navigate = useNavigate();
  const [realestate, setRealEstate] = useState([]);

  const renderRealEstate = async () => {
    try {
      const response = await api.get("/buildings", {
        params: {
          finality: ["residential"],
          building_ids: [61169, 61277],
        },
      });

      setRealEstate([property, ...response.data.buildings]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    renderRealEstate();
  }, []);

  return (
    <Container>
      <Content>
        <TitleHeader
          title="Destaques Singular Realty"
          subtitle="Imóveis selecionados especialmente para você"
          className="title"
        />

        <ContainerRealEstate>
          {realestate.map((item, index) => {
            const {
              id,
              name,
              default_image,
              address,
              neighborhood,
              min_area,
              squareMeters,
              min_parking,
              parkingSpots,
              min_bedrooms,
              suites,
              min_suites,
              price,
            } = item;

            return (
              <SingularHighlightCard
                image={default_image?.["520x280"]}
                location={address?.area || neighborhood}
                squareMeters={min_area || squareMeters}
                parkingSpaces={min_parking || parkingSpots}
                dormitory={min_bedrooms || suites}
                name={name}
                suite={min_suites || suites}
                onClick={() => navigate(`/imoveis/${id || index}`)}
                value={
                  id === 1 &&
                  price.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }
                key={id || index}
              />
            );
          })}
        </ContainerRealEstate>

        <Button onClick={() => navigate("/destaques-singular-realty")}>
          VEJA TODOS OS DESTAQUES
        </Button>
      </Content>
    </Container>
  );
};

export default SingularRealtyHighlights;
